var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.trendingProductAddToBag = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      $(context).off('click.addAllToBag').on('click.addAllToBag', '.js-product-add-all-to-bag', function (e) {
        e.preventDefault();
        var $addToBagBtn = $(this);
        var skuBaseIdAttribute = $addToBagBtn.attr('data-sku-base-id');
        var skuBaseIds = skuBaseIdAttribute ? skuBaseIdAttribute.split(',') : false;
        var quantity = parseInt($addToBagBtn.attr('data-qty'));
        var options = {};
        var skuData;

        if (!skuBaseIds || !skuBaseIds[0]) {
          return false;
        }
        skuData = prodcat.data.getSku(skuBaseIds[0]);
        // Safety check for shopping status.
        if (!skuData.isShoppable) {
          return false;
        }

        // Init options with skubaseid.
        options.skuBaseId = options.SKU_BASE_ID = (function () {
          var skus = [];

          skuBaseIds.forEach(function (sku) {
            skus.push(sku.trim());
          });

          return skus;
        })();
        // If quantity provided, use it.
        if (!!quantity) {
          options.quantity = quantity;
        }
        // Add to cart.
        prodcat.ui.addToCart(options);
      });
    },
    attached: false
  };

  // Update add to cart base id when sku changes.
  $(document).on('product.skuSelect', '.js-product-ui', function (e, skuBaseId) {
    var $product = $(this);
    var $addBtn = $('.js-product-add-to-bag', $product);

    // Update sku base id on button.
    $addBtn.attr('data-sku-base-id', skuBaseId);
  });
})(jQuery);
